// Styles.js

export const breadcrumbStyle = {
    paddingTop:"5px",
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#006db3'

};

export const folderStyle = {
    flex: '1 1 auto', // Allows the card to grow and shrink
    margin: '10px', // Adds space between cards
    minWidth: '200px', // Ensures a minimum width for each card
    height:"100%",
    backgroundColor: '#deeffb',
    cursor: 'pointer',
    boxShadow: 'none',
    border: 'none',
};

export const fileStyle = {
    height:"100%",
    backgroundColor: '#deeffb',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
};

export const downloadButtonStyle = {
    marginLeft:"0.3rem",
    color: '#ffffff',
    fontSize:"1.1rem",
    ':disabled': {
        backgroundColor: '#75bae7',
        color: '#ffffff'
    }
};

export const searchFieldStyle = {
    width:"15rem",
    fontSize:"1.1rem",
    flex: 1,
    marginRight: '10px',
    marginTop: '5px',
    border:"black",
};

import React, { useState, useEffect } from 'react';
import { Box, Grid, Card, CardContent, Typography, Breadcrumbs, Checkbox, Button, TextField, Divider, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { FolderOpenOutlined, FileCopyOutlined, Download as DownloadIcon } from '@mui/icons-material';
import { FcOpenedFolder } from "react-icons/fc";
import { parseS3Keys } from './Utils';
import { folderStyle, fileStyle, breadcrumbStyle, downloadButtonStyle, searchFieldStyle } from './Styles';
import { getFileIcon } from './Icons';
import HomeIcon from '@mui/icons-material/Home';
import { API } from '../MyApi';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import CircularProgress from '@mui/material/CircularProgress';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import GradingIcon from '@mui/icons-material/Grading';
import CancelIcon from '@mui/icons-material/Cancel';

const sortItems = (items) => {
    return items.slice().sort((a, b) => {
        if (a.type === 'folder' && b.type === 'file') return -1;
        if (a.type === 'file' && b.type === 'folder') return 1;
        return 0;
    });
};

const containerStyle = {
    maxHeight: 'calc(100vh - 240px)', // Adjust based on your layout needs
    overflowY: 'auto',
    scrollbarWidth: 'thin', // For Firefox
    msOverflowStyle: 'none', // For Internet Explorer and Edge
};

const scrollableContainerStyle = {
    '&::-webkit-scrollbar': {
        width: '8px', // Adjust width as needed
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#888', // Adjust color as needed
        borderRadius: '4px', // Rounded corners
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: '#f1f1f1', // Track color
    },
};

const FileManager = ({ s3keylist, setS3KeyList, setOpen, selectedFamily, setSelectedFamily }) => {
    const [path, setPath] = useState([]);
    const [loadtext, setLoadText] = useState(null);
    const [items, setItems] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const parsedData = parseS3Keys(s3keylist);
        setSelectedFiles([]);
        setItems(parsedData);
    }, [s3keylist]);

    useEffect(() => {
        // setPath(path.slice(0, 0));
        get_result_s3keys_list();
    }, [selectedFamily]);

    // ==============================================API===================
    useEffect(() => {
        setSelectedFiles([])
        setSearchTerm("")
        console.log("Path", path)
        if (path.length === 1) {
            setSelectedFamily(path[0])
        }
        else if (path.length === 2) {
            get_chiller_data_from_s3()
        }
    }, [path]);

    const get_result_s3keys_list = async () => {
        setOpen(true);
        setLoadText("Fetching " + selectedFamily + " chillers..")
        console.log('[Download files] family has changed:', selectedFamily);
        const param = { "family": selectedFamily };
        try {
            const response = await API.getData("/get_result_s3keys_list", param);
            console.log("Received Response", response)
            if (response) {
                setS3KeyList(prevKeys => [...prevKeys, ...response]);
                setLoadText(null)
            }
        } catch (error) {
            console.error("Failed to fetch chiller list:", error);
            setS3KeyList([])
        } finally {
            setOpen(false);
        }
    };

    const get_chiller_data_from_s3 = async () => {
        setLoadText("Fetching " + path[1] + " unit files..")
        console.log('Getting nested chiller list:', path[0], path[1]);
        const param = { "family": path[0], "chiller": path[1] };
        try {
            const response = await API.getData("/get_chiller_data_from_s3", param);
            if (response) {
                setS3KeyList(prevKeys => [...prevKeys, ...response]);
                setLoadText(null)
            }
        } catch (error) {
            console.error("Failed to fetch chiller list:", error);
            setS3KeyList([])
        } finally {
        }
    };

    const handleFileDownloadClick = (fileKey) => {
        download_single_file_from_s3(fileKey);
    };
    const download_single_file_from_s3 = async (s3key) => {
        setOpen(true)
        try {
            const param = { s3key: [s3key] };
            try {
                const pre_sign_link = await API.postData("/download_file_from_s3", param);

                if (pre_sign_link) {
                    setOpen(false)
                    if (pre_sign_link.includes('.json')) {
                        window.open(pre_sign_link, '_blank');
                    } else if (pre_sign_link.includes('.html')) {
                        window.location.assign(pre_sign_link);
                    } else {
                        window.location.assign(pre_sign_link);
                    }
                }
            } catch (error) {
                console.error(`Error fetching presigned link for ${s3key}:`, error);
            }
        } catch (error) {
            console.error("Error downloading files:", error);
        }
    };

    const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    const download_file_from_s3 = async () => {
        setOpen(true)
        try {
            for (const s3key of selectedFiles) {
                await sleep(400);
                const param = { s3key: [s3key] };
                try {
                    const pre_sign_link = await API.postData("/download_file_from_s3", param);

                    if (pre_sign_link) {
                        setOpen(false)
                        if (pre_sign_link.includes('.json')) {
                            window.open(pre_sign_link, '_blank');
                        } else if (pre_sign_link.includes('.html')) {
                            window.location.assign(pre_sign_link);
                        } else {
                            window.location.assign(pre_sign_link);
                        }
                    }
                } catch (error) {
                    console.error(`Error fetching presigned link for ${s3key}:`, error);
                }
            }
        } catch (error) {
            console.error("Error downloading files:", error);
        }
    };



    // ==============================================API===================
    const handleFolderClick = (folderName) => {
        setSearchTerm("")
        setPath([...path, folderName]);
    };

    const handleBreadcrumbClick = (index) => {
        setPath(path.slice(0, index));
        setSelectedFiles([]);
    };

    const handleFileSelect = (fullKey) => {
        setSelectedFiles(prev => prev.includes(fullKey) ? prev.filter(f => f !== fullKey) : [...prev, fullKey]);
    };

    const handleDownload = () => {
        console.log("Downloading files:", selectedFiles);
        download_file_from_s3();
    };
    const handleSelectAll = () => {
        let keys = [];

        const traverse = (nodes) => {
            nodes.forEach(node => {
                if (node.type === 'file' && node.fullKey) {
                    keys.push(node.fullKey);
                }
                // if (node.children && node.children.length > 0) {
                //     traverse(node.children);
                // }
            });
        };
        traverse(sortedItems);
        setSelectedFiles(keys);
        console.log("Selecting files:", selectedFiles.length);
    };

    const handleDeSelectAll = () => {
        setSelectedFiles([]);
    };

    const currentItems = path.reduce((acc, folder) => acc.find(item => item.name === folder)?.children || [], items);

    const filteredItems = currentItems.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()));
    const sortedItems = sortItems(filteredItems);

    return (
        <Box style={{ height: '100%', padding: '20px', backgroundColor: '#deeffb', boxShadow: "rgb(106, 156, 218) 0px 0px 7px 0px", }}>
            <Box style={{
                padding: "10px",
                boxShadow: "rgb(106, 156, 218) 0px 0px 7px 0px",
                borderRadius: '8px', display: 'flex', justifyContent: 'space-between', marginBottom: '20px'
            }}>
                <Breadcrumbs separator=">" aria-label="breadcrumb" style={breadcrumbStyle}>
                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => setPath([])}>
                        <HomeIcon style={{ marginRight: '0.5rem', fontSize: '28px' }} />
                        <Typography
                            style={{ fontFamily: 'monospace', color: '#1f5291', fontSize: '20px', fontWeight: 'bold' }}
                        >
                            HOME
                        </Typography>
                    </div>
                    {path.map((folder, index) => (
                        <Typography
                            key={index}
                            style={{ fontFamily: 'monospace', cursor: 'pointer', color: '#1f5291', fontSize: '20px', fontWeight: 'bold' }}
                            onClick={() => handleBreadcrumbClick(index + 1)}
                        >
                            {folder.toUpperCase()}
                        </Typography>
                    ))}
                </Breadcrumbs>

                <Box style={{ display: 'flex' }}>
                    <TextField
                        color='primary'
                        placeholder="Search"
                        variant="outlined"
                        value={searchTerm}
                        size="small"
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={searchFieldStyle}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<GradingIcon style={{ fontSize: "1.5rem" }} />}
                        disabled={path.length < 3}
                        onClick={handleSelectAll}
                        style={downloadButtonStyle}
                    >
                        Select all
                    </Button>
                    <Button
                        variant="contained"
                        color='error'
                        startIcon={<CancelIcon style={{ fontSize: "1.5rem" }} />}
                        disabled={selectedFiles.length === 0}
                        onClick={handleDeSelectAll}
                        style={downloadButtonStyle}
                    >
                        Clear
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<DownloadIcon style={{ fontSize: "1.5rem" }} />}
                        onClick={handleDownload}
                        disabled={selectedFiles.length === 0}
                        style={downloadButtonStyle}
                    >
                        Download ({selectedFiles.length})
                    </Button>
                </Box>
            </Box>
            <Grid container spacing={2} style={{ ...containerStyle, ...scrollableContainerStyle }}>
                {loadtext != null ? (
                    <Grid container style={{ display: "flex", alignItems: "left", marginLeft: "3rem", marginTop: "3rem" }}>
                        <CircularProgress style={{ marginRight: '1rem' }} />
                        <Typography
                            variant="h5"
                            component="div"
                            style={{
                                fontFamily: "monospace",
                                textAlign: 'center',
                                color: '#006db3',
                                margin: 0
                            }}
                        >
                            {loadtext}
                        </Typography>
                    </Grid>
                ) : sortedItems.length === 0 ? (
                    <Grid item xs={12}>
                        <Typography variant="h4" component="div" style={{ marginTop: "1rem", fontFamily: "monospace", textAlign: 'center', color: '#006db3' }}>
                            No Files Found
                        </Typography>
                    </Grid>
                ) : (
                    sortedItems.map((item) => (
                        item.type === 'folder' ? (
                            <Grid item xs={12} key={item.name} style={{ paddingTop: 0 }}>
                                <Divider />
                                <List>
                                    <ListItemButton
                                        onClick={() => handleFolderClick(item.name)}
                                        dense
                                    >
                                        <Grid container style={{ display: "flex", alignItems: "left", paddingLeft: "2.5rem", marginTop: "0rem" }}>
                                            <ListItemIcon >
                                                <FcOpenedFolder size="2em" />
                                            </ListItemIcon>

                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        textTransform="uppercase"
                                                        variant="h6"
                                                        component="div"
                                                        style={{ fontFamily: "monospace", color: '#006db3', overflow: 'hidden', textOverflow: 'ellipsis', paddingTop: 0 }}
                                                    >
                                                        {item.name.replace(/_/g, ' ')}
                                                    </Typography>
                                                }
                                            />
                                        </Grid>
                                    </ListItemButton>
                                </List>
                            </Grid>
                        ) : (
                            item.name.length > 1 && (
                                <Grid item xs={12} key={item.fullKey} style={{ paddingTop: 0 }}>
                                    <Divider />
                                    <List>
                                        <ListItemButton
                                            style={{ paddingTop: 0, paddingLeft: '16px', paddingRight: '16px' }}
                                            onClick={() => handleFileSelect(item.fullKey)}
                                            dense
                                        >
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={selectedFiles.includes(item.fullKey)}
                                                    tabIndex={-1}
                                                    disableRipple
                                                />
                                            </ListItemIcon>

                                            <ListItemIcon>
                                                {getFileIcon(item.name)}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        variant="h6"
                                                        component="div"
                                                        style={{ fontFamily: "monospace", color: '#006db3', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                    >
                                                        {item.name.replace(/_/g, ' ')} {/* Replace underscores with spaces */}
                                                    </Typography>
                                                }
                                            />
                                            <ListItemIcon>
                                                <IconButton onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleFileDownloadClick(item.fullKey);
                                                }} color="primary">
                                                    <DownloadForOfflineIcon style={{ fontSize: "3rem", }} />
                                                </IconButton>
                                            </ListItemIcon>

                                        </ListItemButton>
                                    </List>
                                </Grid>
                            )
                        )
                    ))
                )}
            </Grid>

        </Box>
    );
};

export default FileManager;

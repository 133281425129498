import React, { lazy, Suspense, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useAppContext } from './AppContext';
import { TextField, InputAdornment, IconButton, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

// Lazy load ChillerLogPage component
const ChillerLogPage = lazy(() => import('./SubPages/ChillerLogPage'));

// TabPanel component
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function Content() {
  const theme = useTheme();
  const [filterText, setFilterText] = useState('');
  const { CustomTooltip, open, chillers_list } = useAppContext();
  const [value, setValue] = useState(0);
  const [loadedTabs, setLoadedTabs] = useState(new Set([0])); // Initially load the first tab

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setLoadedTabs(prevLoadedTabs => new Set(prevLoadedTabs).add(newValue));
  };

  const handleChangeIndex = (index) => {
    setValue(index);
    setLoadedTabs(prevLoadedTabs => new Set(prevLoadedTabs).add(index));
  };

  const tabStyle = {
    marginTop: -1,
    padding: 0,
    alignItems: 'center',
    color: 'white',
    fontSize: 'large',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: '0.2rem',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      borderRadius: "10px",
    }
  };

  const tabDivStyle = {
    display: 'flex', alignItems: 'center', width: "100%", justifyContent: 'space-between', gap: "0rem", color: "white"
  };

  const handleFilterChange = (event) => {
    setFilterText(event.target.value);
  };
  const handleClear = () => {
    setFilterText(''); // Clear the input field
  };
  const completedIcon = <CheckCircleIcon style={{ color: '#4cff00', fontSize: '1.5rem' }} />;
  const filteredChillers = chillers_list.filter(chiller =>
    chiller.name.toLowerCase().includes(filterText.toLowerCase())
  );
  return (
    <Paper sx={{ maxWidth: "100%", margin: 'auto' }}>
      <AppBar component="div" position="static" elevation={0} sx={{ width: '100%', borderRadius: '8px', boxShadow: "rgb(106 156 218) 0px 0px 19px 0px" }}>
        <Toolbar>
          <Grid container>
            <Grid item xs={2}>
              <TextField
                label="Search Chillers"
                value={filterText || ''}
                onChange={handleFilterChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {filterText && (
                        <IconButton
                          edge="end"
                          onClick={handleClear}
                          aria-label="clear"
                          sx={{ color: 'white' }} // White clear button color
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  )
                }}
                sx={{
                  textAlign: 'left',
                  marginTop: "1rem",
                  color: "white", // Text color
                  borderRadius: "8px",
                  width: "16rem",
                  border: "1px solid white", // White border
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'white', // Border color
                    },
                    '&:hover fieldset': {
                      borderColor: 'white', // Border color on hover
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'white', // Border color when focused
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: 'white', // White label color
                  },
                  '& .MuiInputBase-input': {
                    color: 'white', // White text color inside the field
                  },
                }}
              />
              <Tabs
                orientation="vertical"
                value={value}
                onChange={handleChange}
                variant="scrollable"
                sx={{ alignItems: 'flex-start', textAlign: 'left', height: "40rem" }}
              >
                {chillers_list && filteredChillers.map((chiller, index) => (
                  <Tab
                    key={chiller.id}
                    label={
                      <div style={tabDivStyle}>
                        <>{chiller.name} {completedIcon}</>
                      </div>
                    }
                    icon={<AlignHorizontalLeftIcon style={{ color: "white" }} />}
                    {...a11yProps(index)}
                    sx={tabStyle}
                  />
                ))}
              </Tabs>
            </Grid>
            <Grid item xs={10}>
              <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                {filteredChillers.length > 0 ? (
                  chillers_list && filteredChillers.map((chiller, index) => (
                    <TabPanel key={chiller.id} value={value} index={index} dir={theme.direction}>
                      {loadedTabs.has(index) ? (
                        <Suspense fallback={<div>Loading...</div>}>
                          <ChillerLogPage chillerId={chiller.id} chiller={chiller.name} />
                        </Suspense>
                      ) : (
                        <div>Content is being loaded...</div>
                      )}
                    </TabPanel>
                  ))
                ) : (
                  <Grid container style={{ marginTop:"6rem", display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                    <ErrorOutlineIcon style={{ marginRight: '1rem', fontSize:"3rem" }} />
                    <Typography
                      variant="h4"
                      component="div"
                      style={{
                        fontFamily: 'monospace',
                        color: 'white',
                        margin: '1rem',
                      }}
                    >
                      No chillers found
                    </Typography>
                  </Grid>

                )}
              </SwipeableViews>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Paper>
  );
}

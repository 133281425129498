// const tenant = 'unob2c.onmicrosoft.com';

export const appConfig = {
  api: {
    // apiUrl: "https://devapi.carrierappshub1.com/",

    // uiUrl: "https://dev.d2hor9274pjp8v.amplifyapp.com",

    // redirectAppUri: "https://authdev.carrierappshub1.com",

    // postLogoutRedirectUri: "http://localhost:3000",
    apiUrl: "https://api.carrierappshub1.com/",

    uiUrl: "https://dev-fddpr-ui-dashboard.carrier.com",

    postLogoutRedirectUri: "http://localhost:3000",
  },
};
export default appConfig;

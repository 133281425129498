import React, { useState } from 'react';
import { Box } from '@mui/material';
import Header from '../Header';
import { Typography } from '@material-ui/core';
import FileManager from './FileManager'
import './styles.css';

const Download_App = () => {
  const [s3keylist, setS3keylist] = useState(["30RB/","30RA/","30XA/",'30XV/', "HORIZON/","30KAV/"])
  const [open, setOpen] = useState(false)
  const [selectedFamily, setSelectedFamily] = useState("30RB");


  return (
    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <Header name="DOWNLOAD FDD RESULTS" />
      <Box
        sx={{
          marginLeft: "2rem",
          marginTop: "1.5rem",
          width: "95%",
          flex: 0,
          py: 0,
          px: 2,
          boxShadow: "rgb(106 156 218) 0px 0px 8px 0px",
          borderRadius: '8px',
        }}
      >
        {/* <ChillerBar setS3keylist={setS3keylist} setOpen={setOpen} open={open} selectedFamily={selectedFamily} setSelectedFamily={setSelectedFamily}/> */}
      </Box>
      <Box sx={{ flex: 1, py: -10, px: 3 }}>
        <FileManager s3keylist={s3keylist}  setOpen={setOpen} setS3KeyList={setS3keylist} setSelectedFamily = {setSelectedFamily} selectedFamily={selectedFamily}/>
      </Box>
    </Box>


  );
};

export default Download_App;

import React from 'react';
import { Modal, Button, Grid, Typography } from '@material-ui/core';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

const SuccessModal = ({ open, onClose, stepFunctionLink, cloudWatchLink, failed }) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="status-modal-title"
            aria-describedby="status-modal-description"
        >
            <div style={modalStyle}>
                {failed ? (
                    <>
                        <ErrorIcon style={{ fontSize: 60, color: 'red' }} />
                        <Typography variant="h5" component="h2" style={{ marginTop: 10 }}>
                            Step Function Failed
                        </Typography>
                        <Typography variant="body1" style={{ marginTop: 10 }}>
                            There was an issue triggering the Step Function. Please try again later.
                        </Typography>
                    </>
                ) : (
                    <>
                        <CheckCircleIcon style={{ fontSize: 60, color: 'green' }} />
                        <Typography variant="h5" component="h2" style={{ marginTop: 10 }}>
                            Step Function Triggered Successfully!
                        </Typography>
                        <Grid container spacing={2} style={{ marginTop: 20 }}>
                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    style={{ fontSize: 18 }}
                                    onClick={() => window.open(stepFunctionLink, '_blank')}
                                >
                                    Open Step Function Execution
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    fullWidth
                                    style={{ fontSize: 18 }}
                                    onClick={() => window.open(cloudWatchLink, '_blank')}
                                >
                                    Open CloudWatch Log Group
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                )}
            </div>
        </Modal>
    );
};

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50rem",
    backgroundColor: 'white',
    padding: 20,
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
    textAlign: 'center',
};

export default SuccessModal;

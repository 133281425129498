import React from 'react';
import { FcRules, FcKindle, FcImageFile, FcFile, FcDocument, FcDataSheet } from 'react-icons/fc';
import { FcDiploma2 } from "react-icons/fc";

// Define custom icons for specific file types
const fileIcons = {
    'csv': <FcDataSheet size="2em"/>,
    'xlsx': <FcDataSheet size="2em"/>,
    'xls': <FcDataSheet size="2em"/>,
    'parquet': <FcDataSheet size="2em"/>,
    'log': <FcDocument size="2em"/>,
    'txt': <FcDocument size="2em"/>,
    'json': <FcDocument size="2em"/>,
    'html': <FcDiploma2 size="2em"/>,
    'xml': <FcDocument size="2em"/>,
    'pdf': <FcKindle size="2em" />,
    'zip': <FcFile size="2em" />,
    'docx': <FcDocument size="2em"/>,
    'pptx': <FcDocument size="2em"/>,
    'png': <FcImageFile size="2em"/>,
    'jpg': <FcImageFile  size="2em"/>
};

// Function to get the appropriate file icon
export const getFileIcon = (filename) => {
    const extension = filename.split('.').pop().toLowerCase(); // Convert extension to lowercase for consistency
    return fileIcons[extension] || <FcFile size="2em"/>; // Default icon if extension not found
};

import React, { useEffect, useState } from 'react';
import { Box, TextField, Button, FormControlLabel, Checkbox, MenuItem, Select, Divider } from '@mui/material';
import { Modal, Grid, Typography } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import { API } from '../MyApi';
import Autocomplete from '@mui/material/Autocomplete';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import SuccessModal from './SuccessModal';
import { css } from '@emotion/react';

// Styles for modal box
const StyledModalBox = styled(Box)({
    width: '100%',
    maxWidth: '70%',
    maxHeight: "90%",
    padding: '2rem',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
        width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
        background: "#f1f1f1",
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#888',
    },
    '&::-webkit-scrollbar-thumb:hover': {
        background: '#555'
    }
});

const typographyStyles = css`
  margin-top: 0rem !important;
  padding: 0.5rem;
  margin-bottom: 2rem !important;
  background-color: #deeffb;
  text-align: center;
  font-weight: bold !important;
  font-size: 1.1rem;
  border: 0.1px solid white;
`;
// Styles for typography
const StyledTypography = styled(Typography)(({ theme }) => typographyStyles);

// Styles for select input
const FamilySelect = styled(Select)({
    fontSize: '1rem',
    width: '100%',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
});

// Styles for chiller text field
const ChillerTextField = styled(TextField)({
    fontSize: '1rem',
    width: '100%',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
});

// Styles for job boxes
const JobBox = styled(Box)({
    padding: '0.5rem',
    margin: '0.5rem 0',
    borderRadius: '8px',
    boxShadow: '0px 4px 9px 6px rgb(7 51 8 / 10%)',
});

// Styles for inline boxes
const InlineBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-evenly',
    marginBottom: '1rem'
});

// Styles for box heading
const boxHeadingStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 2,
    fontSize: 'larger',
    height: '4rem',
    backgroundColor: '#abe7ab',
    color: 'black',
    borderRadius: '8px',
    padding: 1,
    gap: "1rem",
    border: '1px solid white',
    boxShadow: '0px 4px 9px 6px rgb(7 51 8 / 10%)'
}

const checkboxHeadingStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 1,
    fontSize: 'larger',
    height: '4rem',
    color: 'black',
    borderRadius: '15px',
    padding: 2,
    border: "2px solid #deeffb",
    gap: "1rem",
}

const DividerWithText = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    margin: '1rem 0.5',
    '& .MuiDivider-root': {
        flexGrow: 1,
        borderBottomWidth: '1px',
    },
    '& .MuiTypography-root': {
        margin: '1 1rem',
        fontWeight: 'bold',
        fontSize: "larger"
    },
}));

// Styles for larger checkboxes
const runCheckboxStyle = {
    '& .MuiCheckbox-root': {
        width: 30,
        height: 30
    }
}

const FDDExecutionForm = () => {
    const [loadopen, setLoadpen] = useState(false);
    const [selectedCSNs, setSelectedCSNs] = useState([]);
    const [chillers_list, setChiller_list] = useState([]);
    const [openTriggerModal, setOpenTriggerModal] = useState(false);
    const [stepFunctionLink, setStepFunctionLink] = useState('');
    const [cloudWatchLink, setCloudWatchLink] = useState('');
    const [failed, setFailed] = useState(false);
    const [execIdsList, setExecIdsList] = useState({
        data_cleaning: [],
        l1_calculation: [],
        anamoly_detection: [],
        fault_detection: []
    });
    const [data_cleaning_list, setData_cleaning_list] = useState([]);
    const [l1_calculation_list, setL1_calculation_list] = useState([]);
    const [anamoly_detection_list, setAnamoly_detection_list] = useState([]);
    const [fault_detection_list, setFault_detection_list] = useState([]);

    const [formData, setFormData] = useState({
        family: '30RB',
        chiller: [],
        config: {
            preprocessing: { run: false, prev_exec_id: 0 },
            calculate_l1_results: { run: false, prev_exec_id: 0 },
            anamoly_detection_unit_pointwise: { run: false, prev_exec_id: 0 },
            anamoly_detection_unit_binned: { run: false, prev_exec_id: 0 },
            anamoly_detection_circuit_pointwise: { run: false, prev_exec_id: 0 },
            fault_detection: { run: false, prev_exec_id: 0 }
        },
        persistsCSVS: false
    });

    //  =======================================    API  ======================
    useEffect(() => {
        if (formData.family !== undefined) {
            // Reset list before fetching new data
            get_chiller_list();
        }
    }, [formData.family]);

    useEffect(() => {
        if (formData.family !== undefined) {
            // Reset list before fetching new data
            setExecIdsList({
                data_cleaning: [],
                l1_calculation: [],
                anamoly_detection: [],
                fault_detection: []
            })
            get_execution_ids_on_one_chiller();
            clear_prev_ids();
        }
    }, [formData.chiller]);

    const get_chiller_list = async () => {
        setLoadpen(true);
        setSelectedCSNs([]);
        console.log('family has changed:', formData.family);
        const param = { "family": formData.family };
        try {
            const chiller_list = await API.getData("/get_chiller_list", param);
            console.log("chiller_list", chiller_list);

            if (chiller_list && chiller_list.chillers) {
                setChiller_list(chiller_list.chillers);
            }
        } catch (error) {
            console.error("Failed to fetch chiller list:", error);
        } finally {
            setLoadpen(false);
        }
    };

    const clear_prev_ids = () => {
        setData_cleaning_list([]);
        setL1_calculation_list([]);
        setAnamoly_detection_list([]);
        setFault_detection_list([]);
    };

    const get_execution_ids_on_one_chiller = async () => {
        const selectedChillers = formData.chiller;

        if (selectedChillers && Array.isArray(selectedChillers) && selectedChillers.length === 1) {
            console.log("Getting execution ID from chiller:", selectedChillers[0]);
            setLoadpen(true);
            const params = {
                chiller: selectedChillers[0],
                family: formData.family
            };
            try {
                const response = await API.getData("/get_prev_execution_ids", params);
                console.log("Execution list:", response);
                if (response && response.execution_list) {
                    setData_cleaning_list(response.execution_list.data_cleaning);
                    setL1_calculation_list(response.execution_list.l1_calculation);
                    setAnamoly_detection_list(response.execution_list.anamoly_detection);
                    setFault_detection_list(response.execution_list.fault_detection);
                }
            } catch (error) {
                console.error("Failed to fetch execution IDs:", error);
            } finally {
                setLoadpen(false);
            }
        } else if (selectedChillers && Array.isArray(selectedChillers) && selectedChillers.length > 1) {
            setLoadpen(false);
            clear_prev_ids()

        }
    };

    const triggerAwsExecution = async () => {
        console.log("Triggering AWS Step Function with the form information");
        setLoadpen(true);

        const jsonData = JSON.stringify(formData);
        const params = { jsondata: jsonData };

        try {
            // Assuming API.getData makes a GET request with query parameters
            const triggerResp = await API.postData("/trigger_aws_execution", params);
            console.log("Trigger Response:", triggerResp);

            if (triggerResp && triggerResp.resp.status === "success") {
                // Extract and set links from the response
                setStepFunctionLink(triggerResp.resp.step_link);
                setCloudWatchLink(triggerResp.resp.log_link);
                setFailed(false);  // Execution was successful
            } else {
                // Handle unsuccessful status, assuming status is something other than "success"
                setFailed(true);
            }
        } catch (error) {
            console.error("Failed to fetch execution IDs:", error);
            setFailed(true);  // Set failure state on error
        } finally {
            setLoadpen(false);  // Turn off loading indicator
            setOpenTriggerModal(true);  // Open the modal with result
        }
    };



    //  =======================================    API  ======================
    const handleSubmit = () => {
        console.log("FormData:", formData);
        triggerAwsExecution();
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData(prev => ({ ...prev, [name]: checked }));
    };

    const handleFamilyChange = (e) => {
        setFormData(prev => ({ ...prev, family: e.target.value }));
    };

    const handleChillerChange = (event, newValue) => {
        setSelectedCSNs(newValue);
        setFormData(prev => ({ ...prev, chiller: newValue }));
    };
    const processValue = (value) => {
        console.log("populated value:", value)
        if (value === null) {
            return 0;
        }
        if (value.includes('latest')) {
            return 0;
        }
        const match = value.match(/\d+$/);
        return match ? Number(match[0]) : '';
    };

    // const handlePreviousExecIdChange = (event, newValue) => {
    //     setFormData(prev => ({
    //         ...prev,
    //         config: {
    //             ...prev.config,
    //             preprocessing: { ...prev.config.preprocessing, prev_exec_id: newValue || '' },
    //             calculate_l1_results: { ...prev.config.calculate_l1_results, prev_exec_id: newValue || '' },
    //             anamoly_detection_unit_pointwise: { ...prev.config.anamoly_detection_unit_pointwise, prev_exec_id: newValue || '' },
    //             anamoly_detection_unit_binned: { ...prev.config.anamoly_detection_unit_binned, prev_exec_id: newValue || '' },
    //             anamoly_detection_circuit_pointwise: { ...prev.config.anamoly_detection_circuit_pointwise, prev_exec_id: newValue || '' },
    //             fault_detection: { ...prev.config.fault_detection, prev_exec_id: newValue || '' }
    //         }
    //     }));
    // };

    return (
        <Box>
            <Box
                sx={{
                    marginLeft: "0rem",
                    width: "100%",
                    flex: 0,
                    py: 0,
                    px: 2,
                    border: '0.1px solid #92bbd8',
                    boxShadow: "rgb(160 188 222) 5px 4px 15px 0px",
                    borderRadius: '8px',
                }}
            >
                <DividerWithText>
                    <Divider />
                    <Typography style={{ marginTop: "12px" }} variant="body1">UNIT INFORMATION</Typography>
                    <Divider />
                </DividerWithText>
                <Grid container spacing={2} style={{}}>
                    <Grid item xs={2}>
                        <FamilySelect
                            style={{ marginTop: "8px", padding: "3px" }}
                            name="family"
                            value={formData.family}
                            onChange={handleFamilyChange}
                            displayEmpty
                            renderValue={(selected) => selected || 'Family'}
                        >
                            {['30RB', '30RA', '30XA','30XV', 'Horizon', '30KAV'].map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </FamilySelect>
                    </Grid>

                    <Box
                        sx={{
                        }}
                    >
                        <Grid item xs={7}>
                            <div style={{ flex: "20%", marginLeft: "10px", margin: "1rem" }}>
                                <Autocomplete
                                    sx={{
                                        width: "60rem",
                                        border: '1.5px solid #ccc',  // Add a border
                                        borderRadius: '8px',       // Optional: Add border radius for rounded corners
                                        padding: '0.5rem'          // Optional: Add padding inside the border
                                    }}
                                    multiple
                                    size='small'
                                    limitTags={2}
                                    id="combo-box-demo"
                                    options={['Select All', ...chillers_list]}  // Add 'Select All' option
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option}
                                    value={selectedCSNs}
                                    onChange={(event, newValue) => {
                                        if (newValue.includes('Select All')) {
                                            // If 'Select All' is selected, set all chillers as selected
                                            setSelectedCSNs(chillers_list);
                                            setFormData(prev => ({ ...prev, chiller: chillers_list }));
                                        } else {
                                            // Handle other selections
                                            setSelectedCSNs(newValue);
                                            setFormData(prev => ({ ...prev, chiller: newValue }));
                                        }
                                    }}
                                    isOptionEqualToValue={(option, value) => option === value || value === ""}
                                    renderInput={(params) => (
                                        <TextField
                                            style={{ marginBottom: "0.1rem" }}
                                            {...params}
                                            variant="standard"
                                            label="Select Chillers"
                                            placeholder="Units"
                                            InputProps={{
                                                ...params.InputProps,
                                                disableUnderline: true
                                            }}
                                        />
                                    )}
                                />

                            </div>
                        </Grid>
                    </Box>

                    {/* <Grid item xs={2}>
                    </Grid> */}

                    <Grid item xs={2}>
                        <Box sx={checkboxHeadingStyle} style={{ marginTop: "8px" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.persistsCSVS}
                                        onChange={handleCheckboxChange}
                                        name="persistsCSVS"
                                        sx={{ marginRight: 0 }}
                                    />
                                }
                                label="Save CSVs"
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{
                marginTop: "1rem",
                padding: "1rem",
                width: "100%",
                flex: 0,
                py: 0,
                px: 2,
                boxShadow: "rgb(160 188 222) 5px 4px 15px 0px",
                borderRadius: '8px',
            }}>
                <DividerWithText>
                    <Divider />
                    <Typography style={{ marginTop: "12px" }} variant="body1">WORKFLOW STEPS</Typography>
                    <Divider />
                </DividerWithText>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <JobBox>
                            <StyledTypography variant="subtitle1">1.Data Cleaning</StyledTypography>
                            <InlineBox>
                                <Box sx={checkboxHeadingStyle}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formData.config.preprocessing.run}
                                                onChange={(e) => setFormData(prev => ({
                                                    ...prev,
                                                    config: {
                                                        ...prev.config,
                                                        preprocessing: { ...prev.config.preprocessing, run: e.target.checked }
                                                    }
                                                }))}
                                                sx={runCheckboxStyle}
                                            />
                                        }
                                        label="Run Execution"
                                    />
                                </Box>
                                <TextField
                                    type="number"
                                    name="preprocessing_prev_exec_id"
                                    label="No Previous Execution ID"
                                    variant="outlined"
                                    style={{ width: "20rem", marginTop: "5px" }}
                                    disabled
                                    onChange={(e) => setFormData(prev => ({
                                        ...prev,
                                        config: {
                                            ...prev.config,
                                            preprocessing: { ...prev.config.preprocessing, prev_exec_id: Number(e.target.value) }
                                        }
                                    }))}
                                    fullWidth
                                />
                            </InlineBox>
                        </JobBox>
                    </Grid>
                    <Grid item xs={6}>
                        <JobBox>
                            <StyledTypography variant="subtitle1">2.L1 Calculations</StyledTypography>
                            <InlineBox>
                                <Box sx={checkboxHeadingStyle}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formData.config.calculate_l1_results.run}
                                                onChange={(e) => setFormData(prev => ({
                                                    ...prev,
                                                    config: {
                                                        ...prev.config,
                                                        calculate_l1_results: { ...prev.config.calculate_l1_results, run: e.target.checked }
                                                    }
                                                }))}
                                                sx={runCheckboxStyle}
                                            />
                                        }
                                        label="Run Execution"
                                    />
                                </Box>
                                <Autocomplete
                                    disabled={formData.config.preprocessing.run || formData.chiller.length > 1 || !formData.config.calculate_l1_results.run}
                                    options={data_cleaning_list}
                                    onChange={(event, newValue) => {
                                        const numberValue = processValue(newValue);
                                        setFormData(prev => ({
                                            ...prev,
                                            config: {
                                                ...prev.config,
                                                calculate_l1_results: { ...prev.config.calculate_l1_results, prev_exec_id: numberValue }
                                            }
                                        }));
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Previous Execution ID"
                                            variant="outlined"
                                            style={{ width: "20rem", marginTop: "5px" }}
                                            fullWidth
                                        />
                                    )}

                                />
                            </InlineBox>
                        </JobBox>
                    </Grid>
                    <Grid item xs={6}>
                        <JobBox>
                            <StyledTypography variant="subtitle1">3.Anomaly Detection</StyledTypography>
                            <InlineBox>
                                <Box sx={checkboxHeadingStyle}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formData.config.anamoly_detection_unit_pointwise.run}
                                                onChange={(e) => setFormData(prev => ({
                                                    ...prev,
                                                    config: {
                                                        ...prev.config,
                                                        anamoly_detection_unit_pointwise: { ...prev.config.anamoly_detection_unit_pointwise, run: e.target.checked }
                                                    }
                                                }))}
                                                sx={runCheckboxStyle}
                                            />
                                        }
                                        label="Unit Pointwise"
                                    />
                                </Box>
                                <Box sx={checkboxHeadingStyle}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formData.config.anamoly_detection_unit_binned.run}
                                                onChange={(e) => setFormData(prev => ({
                                                    ...prev,
                                                    config: {
                                                        ...prev.config,
                                                        anamoly_detection_unit_binned: { ...prev.config.anamoly_detection_unit_binned, run: e.target.checked }
                                                    }
                                                }))}
                                                sx={runCheckboxStyle}
                                            />
                                        }
                                        label="Unit Binned"
                                    />
                                </Box>
                                <Box sx={checkboxHeadingStyle}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formData.config.anamoly_detection_circuit_pointwise.run}
                                                onChange={(e) => setFormData(prev => ({
                                                    ...prev,
                                                    config: {
                                                        ...prev.config,
                                                        anamoly_detection_circuit_pointwise: { ...prev.config.anamoly_detection_circuit_pointwise, run: e.target.checked }
                                                    }
                                                }))}
                                                sx={runCheckboxStyle}
                                            />
                                        }
                                        label="Circuit Pointwise"
                                    />
                                </Box>
                                <Autocomplete
                                    disabled={
                                        formData.config.calculate_l1_results.run ||
                                        formData.chiller.length > 1 ||
                                        !formData.config.anamoly_detection_unit_pointwise.run ||
                                        !formData.config.anamoly_detection_unit_binned.run ||
                                        !formData.config.anamoly_detection_circuit_pointwise.run
                                    }
                                    options={l1_calculation_list}
                                    onChange={(event, newValue) => {
                                        const numberValue = processValue(newValue);
                                        setFormData(prev => ({
                                            ...prev,
                                            config: {
                                                ...prev.config,
                                                anamoly_detection_unit_pointwise: { ...prev.config.anamoly_detection_unit_pointwise, prev_exec_id: numberValue },
                                                anamoly_detection_unit_binned: { ...prev.config.anamoly_detection_unit_binned, prev_exec_id: numberValue },
                                                anamoly_detection_circuit_pointwise: { ...prev.config.anamoly_detection_circuit_pointwise, prev_exec_id: numberValue }
                                            }
                                        }));
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Previous Execution ID"
                                            variant="outlined"
                                            style={{ width: "12rem", marginTop: "5px" }}
                                            fullWidth
                                        />
                                    )}

                                />
                            </InlineBox>
                        </JobBox>
                    </Grid>
                    <Grid item xs={6}>
                        <JobBox>
                            <StyledTypography variant="subtitle1">4.Fault Detection</StyledTypography>
                            <InlineBox>
                                <Box sx={checkboxHeadingStyle}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formData.config.fault_detection.run}
                                                onChange={(e) => setFormData(prev => ({
                                                    ...prev,
                                                    config: {
                                                        ...prev.config,
                                                        fault_detection: { ...prev.config.fault_detection, run: e.target.checked }
                                                    }
                                                }))}
                                                sx={runCheckboxStyle}
                                            />
                                        }
                                        label="Run Execution"
                                    />
                                </Box>
                                <Autocomplete
                                    disabled={
                                        formData.config.anamoly_detection_unit_pointwise.run ||
                                        formData.config.anamoly_detection_unit_binned.run ||
                                        formData.config.anamoly_detection_circuit_pointwise.run
                                        || formData.chiller.length > 1 ||
                                        !formData.config.fault_detection.run
                                    }
                                    options={anamoly_detection_list}
                                    onChange={(event, newValue) => {
                                        const numberValue = processValue(newValue);
                                        setFormData(prev => ({
                                            ...prev,
                                            config: {
                                                ...prev.config,
                                                fault_detection: { ...prev.config.fault_detection, prev_exec_id: numberValue }
                                            }
                                        }));
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Previous Execution ID"
                                            variant="outlined"
                                            style={{ width: "23rem", marginTop: "5px" }}
                                            fullWidth
                                        />
                                    )}

                                />
                            </InlineBox>
                        </JobBox>
                    </Grid>
                </Grid>
            </Box>
            <Divider style={{ margin: '1rem 0' }} />
            <Box display="flex" justifyContent="center" marginTop="2rem">
                <Button style={{ "width": "30rem", "fontSize": "larger" }} variant="contained" color="primary" onClick={handleSubmit}>
                    Submit
                </Button>
            </Box>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadopen}
            >
                <CircularProgress size="4rem" />
            </Backdrop>

            <SuccessModal
                open={openTriggerModal}
                onClose={() => setOpenTriggerModal(false)}
                stepFunctionLink={stepFunctionLink}
                cloudWatchLink={cloudWatchLink}
                failed={failed}
            />
        </Box>
    );
};

export default FDDExecutionForm;
